import React, { FunctionComponent } from 'react';
import classNames from "classnames";
// @ts-ignore
import Arrow from '../../assets/icons/ico_rightarrow.svg';
import {Link} from "gatsby";

interface OwnProps extends React.ButtonHTMLAttributes<HTMLElement> {
    disabled?: boolean;
    block?: boolean;
    // active?: boolean;
    chevron?: boolean;
    variant?: "primary" | "secondary" | "border";
    link?: boolean;
}

type Props = OwnProps;

const Button: FunctionComponent<Props> = ({
        className,
        variant = "primary",
        disabled = false,
        chevron = true,
        link = false,
        ...props
    }) => {

    const classes = classNames(
        className,
        'font-serif inline-flex items-center font-semibold rounded-full',
        variant === "primary" && 'px-5 py-2.5 text-white bg-blue-100 hover:bg-blue-110 focus:outline-none focus:ring-2 focus:ring-blue-100',
        variant === "secondary" && 'px-5 py-2.5 text-white bg-brand-100 hover:bg-brand-100 focus:outline-none focus:ring-2 focus:ring-brand-10',
        variant === "border" && 'px-4 py-1.5 border-2 border-black text-black hover:bg-black hover:text-white focus:text-white focus:outline-none focus:bg-ink-60 focus:ring-1 focus:ring-black',
        disabled && 'opacity-50 cursor-not-allowed',
    );
  return (
      <button {...props} className={classes} disabled={disabled}>
          {props.children}
          { chevron ? <Arrow className={'w-6 h-6 ml-1'} style={{'margin-right': '-3px'}}/> : null }
      </button>
  );
};

export default Button;
