import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

export default () => {
    i18n
        .use(initReactI18next)
        .init({
        debug: process.env.NODE_ENV === "development",
        lng: "sk",
        fallbackLng: "en",
        saveMissing: true, // send not translated keys to endpoint
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // not needed for react
        },
        react: { useSuspense: false }
    });

    return i18n;
};
