import React from 'react';
// import useTranslations from '../useTranslations';
// import Button from "./Button/Button";
import LanguageSwitcher from "./LanguageSwitcher";
// import { Link } from 'gatsby';
import Link from "gatsby-link" // not error
import Logo from "../assets/images/legalia-logo.svg";
import Button from "./Button/Button";
import NavigationMenuItem from "./NavigationMenuItem";
import useMenu from "../hooks/useMenu";

export type MenuItem = {
    label: string,
    link?: string,
    hint?: string,
    children?: MenuItem[],
}


const Navigation = (props) => {
    const {mainMenuItems, topMenuItems} = useMenu();

    return (
        <div {...props}>
            <div className={'container'}>
                <div className={'flex justify-between items-center py-4 border-b border-ink-10'}>
                    <LanguageSwitcher/>
                    <div className={'flex space-x-8'}>
                        {topMenuItems.map((menu, index) => (
                            <Link
                                to={menu.link}
                                key={`${menu.link}${index}`}
                                className={'text-sm text-ink-60 opacity-80'}
                            >
                                {menu.label}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className={'flex py-6 items-center justify-between'}>
                    <div>
                        <Link to={'/'}>
                            <img src={Logo} alt=""/>
                        </Link>
                    </div>
                    <div className={'flex space-x-10'}>
                        {mainMenuItems.map((menu, index) => (
                            <>
                                <NavigationMenuItem item={menu} key={`${menu.link}${index}`}/>
                            </>

                        ))}
                    </div>
                    <div>
                        <Button variant={'border'}>
                            <Link to={'/order-form'}>Get started</Link>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
