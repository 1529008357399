import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import { useLocale } from '../hooks/locale';
import Navigation from "../components/Navigation";
import withI18n from "../i18n/withI18n";

const BaseLayout = ({ children, pageContext: { locale, homepage } }) => {
  // Using the useLocale() hook to define the correct locale
  // that will be available in all components of the tree thought its context
  const { changeLocale } = useLocale();
  changeLocale(locale);

  return (
    <>
      <div className="font-serif w-full flex min-h-full flex-col">
        <Navigation className={homepage ? 'bg-brand-5' : ''}/>
        <div className={'w-full flex flex-1 flex-col'} role="main">
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
};

export default withI18n(BaseLayout);
