import React, { FunctionComponent } from 'react';
import {MenuItem} from "./Navigation";
import Link from "gatsby-link";
import Logo from "../assets/images/legalia-logo.svg";
import FacebookIcon from "../assets/icons/ico_facebook.svg";
import YoutubeIcon from "../assets/icons/ico_YouTube.svg";
import LinkedInIcon from "../assets/icons/ico_LinkedIn.svg";
import EnvelopeIcon from "../assets/icons/ico_envelope.svg";

interface OwnProps {}

type Props = OwnProps;

const FooterMenu: MenuItem[] = [
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
            {
                label: 'Document conversion',
                link: '',
            },
            {
                label: 'Contract law',
                link: '',
            },
        ]
    },
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
            {
                label: 'Document conversion',
                link: '',
            },
            {
                label: 'Contract law',
                link: '',
            },
        ]
    },
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
            {
                label: 'Document conversion',
                link: '',
            },
        ]
    },
    {
        label: 'Start of businesses',
        link: '/',
        children: [
            {
                label: 'Freelance',
                link: '',
            },
            {
                label: 'Companies',
                link: '',
            },
            {
                label: 'Differences',
                link: '',
            },
        ]
    },
];

const FooterMenuColumn = ({ item }) => {
    const {label, children = []} = item;

    return (
        <div className={'space-y-4'}>
            <h5 className={'text-h5 font-semibold'}>{label}</h5>
            <ul className={'space-y-3'}>
                {children.map((menu, index) => (
                    <li className='text-center sm:text-left'>
                        <Link
                            to={menu.link}
                            key={`${menu.link}${index}`}
                            className={'text-ink-60'}
                        >
                            {menu.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const Footer: FunctionComponent<Props> = () => {

    return (
        <footer>
            <div className={'container'}>
                <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 border-b border-ink-10 py-14 justify-items-center lg:justify-items-start'}>
                    {/*{FooterMenu.map((menu, index) => <FooterMenuColumn key={`${menu.label}${index}`} item={menu}/>)}*/}
                    <FooterMenuColumn item={FooterMenu[0]}/>
                    <FooterMenuColumn item={FooterMenu[1]}/>
                    <FooterMenuColumn item={FooterMenu[2]}/>
                    <FooterMenuColumn item={FooterMenu[3]}/>
                </div>
                <div className={'flex justify-between items-center py-10 w-full'}>
                    <div>
                        <img src={Logo} alt=""/>
                    </div>
                    <div className={'ml-16'}>
                        <p className={'flex text-blue-100 space-x-3'}>
                            <EnvelopeIcon className={'w-6 h-6 text-blue-100'}/>
                            <a href="mailto:info@legalia.com" className={'underline'}>info@legalia.com</a>
                        </p>
                    </div>
                    <div className={'flex space-x-8 flex-1 justify-end'}>
                        <FacebookIcon className={'w-6 h-6'}/>
                        <LinkedInIcon className={'w-6 h-6'}/>
                        <YoutubeIcon className={'w-6 h-6'}/>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
