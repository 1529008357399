import React, {FunctionComponent, useState} from 'react';
import classNames from "classnames";
import FlagSk from "../assets/icons/flag_sk.svg";
import FlagUs from "../assets/icons/flag_us.svg";
import {MenuItem} from "./Navigation";
import Link from "gatsby-link";
import Dropdown from "./Dropdown";
import useComponentVisible from "../hooks/componentVisible";

type NavigationMenuItemProps = {
    item: MenuItem,
};

const NavigationMenuItem: FunctionComponent<NavigationMenuItemProps> = ({ item }) => {
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    return (
        <div className={'relative'} ref={ref}>
            <div>
                {item.link ? (
                    <Link to={item.link} className={'cursor-pointer text-ink-100 font-semibold'}>
                        {item.label}
                    </Link>
                ) : (
                    <span
                        onClick={() => setIsComponentVisible(!isComponentVisible)}
                        className={'cursor-pointer text-ink-100 font-semibold'}
                    >
                        {item.label}
                    </span>
                )}
            </div>

            { item.children ? (
                <div
                    className={classNames(
                        "origin-top-right absolute z-50 left-0 mt-2 w-80 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none",
                        isComponentVisible ? '' : 'hidden',
                    )}
                    role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
                >
                    <div className="py-1" role="none">
                        <ul>
                            {item.children.map((menu, index) => (
                                <li>
                                    <Link
                                        onClick={() => setIsComponentVisible(false)}
                                        to={menu.link || '/'}
                                        className={'block text-ink-60 font-medium hover:bg-brand-5 px-5 py-2.5 cursor-pointer hover:text-black'}
                                    >
                                        {menu.label}
                                        { menu.hint ? (
                                            <p className={'text-sm text-ink-60 font-normal'}>{menu.hint}</p>
                                        ) : null}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                    </div>
                </div>
            ) : null}

        </div>
    );
};

export default NavigationMenuItem;
