import React, { FunctionComponent, useState } from 'react';
import FlagUs from '../assets/icons/flag_us.svg';
import FlagSk from '../assets/icons/flag_sk.svg';
import DropdownIcon from '../assets/icons/ico_dropdown.svg';
import classNames from "classnames";
import {navigate} from "gatsby";

interface OwnProps {}

type Props = OwnProps;

const LanguageSwitcher: FunctionComponent<Props> = () => {
    const [opened, setOpened] = useState(false);

    const handleClickLanguage = (e, lang) => {
        e.preventDefault();
        // if (locale === lang) return;

        // const url = window.location.pathname.split("/").pop();

        // if (!url) return lang === "sk" ?
        setOpened(false);
        return lang === "sk" ?
            navigate(`/`) :
            navigate(`/${lang}`);

        // const associatedUrls = languageMapping.find(item => {
        //     let hasUrl = false;
        //
        //     Object.entries(item).forEach(([key, value]) => {
        //         if (value.split("/").pop() === url) return hasUrl = true;
        //     });
        //
        //     return hasUrl
        // });

        // if (!associatedUrls) return navigate("/");
        //
        // return lang === "en" ?
        //     navigate(`/${associatedUrls[lang]}`) :
        //     navigate(`/${lang}/${associatedUrls[lang]}`);
    }

    return (
        <div className={"relative"}>
            <div className={'inline-flex items-center justify-center cursor-pointer'} onClick={(e) => setOpened(!opened)}>
                <FlagSk className={'text-lg'}/> <div className={'text-sm text-ink-60 ml-2'}>Slovensky</div>  <DropdownIcon className={'ml-2'}/>
            </div>

            <div
                className={classNames(
                    "origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none",
                    !opened ? 'hidden' : '',
                )}
                role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
            >
                <div className="py-1" role="none">
                    <a
                        href="#"
                        className="group flex items-center px-4 py-2 text-sm text-ink-60 hover:bg-blue-5 hover:text-black hover:font-semibold"
                        role="menuitem"
                        onClick={(e) => handleClickLanguage(e, "sk")}
                    >
                        <FlagSk className={'text-xl'}/>
                        <span className={'inline-block ml-2 text-base'}>Slovak</span>
                    </a>
                    <a
                        href="#"
                        className="group flex items-center px-4 py-2 text-sm text-ink-60 hover:bg-blue-5 hover:text-black hover:font-semibold"
                        role="menuitem"
                        onClick={(e) => handleClickLanguage(e, "en")}
                    >
                        <FlagUs className={'text-xl'}/>
                        <span className={'inline-block ml-2 text-base font-inherit'}>English</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
