import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocale } from './locale';

function useMenu() {
  // Grab the locale (passed through context) from the Locale Provider
  // through useLocale() hook
  const { locale } = useLocale();
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      mainMenuItems: item.node.translations.mainMenu,
      topMenuItems: item.node.translations.topMenu,
    };
  });

  // Only return menu for the current locale
  const { mainMenuItems, topMenuItems } = simplified.filter(
    lang => lang.name === locale,
  )[0];

  return {mainMenuItems, topMenuItems};
}

export default useMenu;

const query = graphql`
  query MyQuery {
    rawData: allFile(filter: {sourceInstanceName: {eq: "menu"}}) {
      edges {
        node {
          id
          name
          translations: childMenuJson {
            mainMenu {
              label
              link
              children {
                label
                hint
                link
              }
            }
            topMenu {
              label
              link
            }
          }
        }
      }
    }
  }
`;
