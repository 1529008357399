import 'lazysizes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './src/styles/global.css';


// require('prismjs/themes/prism-tomorrow.css');

import CustomLayout from './wrapPageElement';

export const wrapPageElement = CustomLayout;
